import imgOne from '../../assets/blogs/1.png';
import imgTwo from '../../assets/blogs/2.png';
import imgThree from '../../assets/blogs/3.png';

const BlogData = [
    {
        'img': imgOne,
        'url': 'https://youtu.be/yxM0EUakSDQ',
        'title':'Full mouth rehabilitation with dental implants',
        'description':'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
        'category': 'TECHNOLOGY'
    },
     {
        'img': imgTwo,
        'url': 'https://youtu.be/psRKGKADK4g',
        'title':'Full Mouth Dental Implants with immediate Ceramic Bridge',
        'description':'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
        'category': 'DENTAL'
    },
    {
        'img': imgThree,
        'url': 'https://youtu.be/MX3F40u0qbg',
        'title':'Anterior Immediate Implants and Immediate Crown',
        'description':'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ',
        'category': 'HYGIENE'
    }
];

export default BlogData;