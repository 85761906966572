import React from 'react';
import './Features.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import IconList from '../../components/IconList/IconList';
import featuresData from './FeaturesData';

const Features = () => {
    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitle style="margin-bottom:0px;" subTitle="Features" title="Specialized  care through experiences" description="Our clinic specialises in Dental Implant Surgeries. It is operated by Dr. Nirav Gada.
He has completed his post-graduation in the field of oral surgery and Implantology from PIMS Loni. He is also a graduate alumnus from government dental college and hospital, Mumbai 2005. 
He has been practising dental Implantology for the past 13 years during which he has placed more than 1000 implants.">
</SectionTitle>

                <div className="row align-items-center">
                    {
                        featuresData.map(singleFeature => 
                            <IconList 
                                icon={singleFeature.icon} 
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Features;