import React from 'react';
import './Emergency.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import EmergencyImg from '../../assets/emergency.png';
import { Link } from 'react-router-dom';

const Emergency = () => {
    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitle subTitle="Location" title="Visit us at our clinic" description="Dr. Gada's speciality Implant clinic, 16B Hanuman Industrial Estate, G.D Ambedkar Road, Wadala West, Mumbai-400031."/>
                            <p>Monday-Saturday: 10 a.m. to 8 p.m.</p>
                            <p>Sunday: 10 a.m. to 2 p.m. (only with prior appointment)</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Emergency;